<template>
    <div class="container-fluid" style="min-height: 30rem;"
         v-loading="isLoading">
        <div class="card p-2" style=" height: calc(100dvh - 7rem);">
            <VuePerfectScrollbar :settings="{ suppressScrollX : false }">
                <table class="table table-striped table-head-fixed table-borderless mt-0 w-100">
                    <thead>
                        <tr>
                            <th class="text-nowrap text-danger">
                                <!--<el-button type="primary" size="small"
                                           :disabled="offsetWeek === 0"
                                           @click="changeWeek(-1)">
                                    <i class="el-icon-d-arrow-left"></i>
                                </el-button>
                                <el-button type="primary" size="small"
                                           @click="changeWeek(1)">
                                    <i class="el-icon-d-arrow-right"></i>
                                </el-button>-->
                            </th>
                            <th style="width: 1%; vertical-align: middle;" class="text-left text-nowrap">
                                <div class="text-orange">Thứ 2</div>
                                <div>{{dayInWeek(0)}}</div>
                            </th>
                            <th style="width: 1%; vertical-align: middle;" class="text-left text-nowrap">
                                <div class="text-orange">Thứ 3</div>
                                <div>{{dayInWeek(1)}}</div>
                            </th>
                            <th style="width: 1%; vertical-align: middle;" class="text-left text-nowrap">
                                <div class="text-orange">Thứ 4</div>
                                <div>{{dayInWeek(2)}}</div>
                            </th>
                            <th style="width: 1%; vertical-align: middle;" class="text-left text-nowrap">
                                <div class="text-orange">Thứ 5</div>
                                <div>{{dayInWeek(3)}}</div>
                            </th>
                            <th style="width: 1%; vertical-align: middle;" class="text-left text-nowrap">
                                <div class="text-orange">Thứ 6</div>
                                <div>{{dayInWeek(4)}}</div>
                            </th>
                            <th style="width: 1%; vertical-align: middle;" class="text-left text-nowrap">
                                <div class="text-danger">Thứ 7</div>
                                <div>{{dayInWeek(5)}}</div>
                            </th>
                            <th style="width: 1%; vertical-align: middle;" class="text-left text-nowrap">
                                <div class="text-danger">Chủ nhật</div>
                                <div>{{dayInWeek(6)}}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="item.id" v-for="(item, indexCalendar) in calendarData">
                            <td class="v-center text-nowrap">
                                <h5 class="m-0">
                                    <strong>
                                        {{item.timeLabel}}
                                    </strong>
                                </h5>
                            </td>
                            <td class="text-left text-nowrap"
                                v-bind:class="{ 'block-current-time' : (currentDayOfWeek === 0 ? 7 === itemDayOfWeek && currentCalendarIndex === indexCalendar : currentDayOfWeek === itemDayOfWeek && currentCalendarIndex === indexCalendar && offsetWeek === 0) }"
                                :key="`${itemDayOfWeek}_${item.id}`" v-for="(itemDayOfWeek) in 7">
                                <el-dropdown v-if="userInfo.userEmail.toLowerCase() === 'duyhh@irender.vn'"
                                              trigger="click">
                                    <div class="el-dropdown-link">
                                        <div class="d-flex align-items-center"
                                             v-if="item[('userInfo' + dayOfWeek[itemDayOfWeek-1])]">
                                            <div>
                                                <v-avatar size="4rem"
                                                          data-toggle="tooltip">
                                                    <v-img id="app-avatar"
                                                           :src="`/img/avatars/${(item[('userInfo' + dayOfWeek[itemDayOfWeek-1])].emailAddress)}.jpg`"
                                                           onerror="this.src='/img/avatar5.png'" />
                                                </v-avatar>
                                            </div>
                                            <div class="text-left ml-2">
                                                <div>
                                                    <strong>{{(item[('userInfo' + dayOfWeek[itemDayOfWeek-1])].fullName)}}</strong>
                                                </div>
                                                <div>
                                                    <small class="text-muted">{{(item[('userInfo' + dayOfWeek[itemDayOfWeek-1])].phoneNumber)}}</small>
                                                </div>
                                                <div>
                                                    <small class="text-muted">{{(item[('userInfo' + dayOfWeek[itemDayOfWeek-1])].emailAddress)}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="d-flex align-items-center">
                                            <div>
                                                <v-avatar size="4rem"
                                                          data-toggle="tooltip">
                                                    <v-img id="app-avatar"
                                                           src="/img/avatar5.png"/>
                                                </v-avatar>
                                            </div>
                                            <div class="text-left ml-2">
                                                <div>
                                                    <strong>Pick staff</strong>
                                                </div>
                                                <div>
                                                    <small class="text-muted">-- -- -- -- -- -- -- -- -- -- -- --</small>
                                                </div>
                                                <div>
                                                    <small class="text-muted">-- -- -- -- -- -- -- -- -- -- -- --</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <el-dropdown-menu slot="dropdown" class="list-staff">
                                        <el-dropdown-item :key="itemStaff.id"
                                                          size="small"
                                                          v-for="itemStaff in staffsInfo.filter((x)=> (x.mainDepartment === 'DEVELOPER' || x.mainDepartment === 'FARMER')
                                                                && x.passwordHash !== null && x.passwordHash !== '' )">
                                            <button class="d-flex align-items-center pt-1 pb-1 text-left btn-block"
                                                    @click="updateCalendar(item.id, itemStaff.id, itemDayOfWeek-1)">
                                                <v-avatar size="2.5rem">
                                                    <img id="app-avatar"
                                                         :src="`/img/avatars/${itemStaff.email}.jpg`"
                                                         onerror="this.src='/img/avatar5.png'" />
                                                </v-avatar>
                                                <div class="ml-2" style="line-height: 1rem">
                                                    <div>
                                                        <strong>{{ itemStaff.fullName }}</strong>
                                                        ({{ itemStaff.code }})
                                                    </div>
                                                    <div>
                                                        <small>
                                                            <i>{{ itemStaff.email }}</i>
                                                        </small>
                                                    </div>
                                                </div>
                                            </button>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <div v-else style="font-size: 90%;">
                                    <div class="d-flex align-items-center"
                                         v-if="item[('userInfo' + dayOfWeek[itemDayOfWeek-1])]">
                                        <div>
                                            <v-avatar size="4rem"
                                                      data-toggle="tooltip">
                                                <v-img id="app-avatar"
                                                       :src="`/img/avatars/${(item[('userInfo' + dayOfWeek[itemDayOfWeek-1])].emailAddress)}.jpg`"
                                                       onerror="this.src='/img/avatar5.png'" />
                                            </v-avatar>
                                        </div>
                                        <div class="text-left ml-2">
                                            <div>
                                                <strong>{{(item[('userInfo' + dayOfWeek[itemDayOfWeek-1])].fullName)}}</strong>
                                            </div>
                                            <div>
                                                <small class="text-muted">{{(item[('userInfo' + dayOfWeek[itemDayOfWeek-1])].phoneNumber)}}</small>
                                            </div>
                                            <div>
                                                <small class="text-muted">{{(item[('userInfo' + dayOfWeek[itemDayOfWeek-1])].emailAddress)}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </VuePerfectScrollbar>
        </div>
    </div><!--//container-->
</template>
<style>
    td.block-current-time {
        background-color: #1eb599 !important;
    }
    td.block-current-time * {
        color: #fff !important;
    }
</style>
<script>
    import { mapState } from "vuex";
    import systemTrackingCalendar from '@/api/common/systemTrackingCalendar.js';
    import moment from "moment";
    moment().isoWeekday(1);
    export default {
        data() {
            return {
                isLoading: false,
                dayOfWeek: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                calendarData: [],
                offsetWeek: 0,
            };
        },
        computed: {
            ...mapState({
                userInfo: (state) => state.auth.user,
                rolesInfo: (state) => state.auth.roles,
                staffsInfo: (state) => state.staffs,
                lowBalanceUser: (state) => state.lowBalanceUser,
                $emailAllowedChangeSource: (state) => state.emailAllowedChangeSource,
                decentralization: (state) => state.auth.decentralization,
                currentCalendarIndex: (state) => parseInt(state.common.calendarIndex),
                currentDayOfWeek:  (state) => parseInt(state.common.currentDayOfWeek),
            })
        },
        mounted() {
            this.getTechnicalShift();
            //console.log("currentDayOfWeek", this.currentDayOfWeek);
        },
        methods: {            
            getTechnicalShift() {
                systemTrackingCalendar.getData(this.offsetWeek).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        response.data.data.forEach(x => {
                            x.userInfoMon = JSON.parse(x.mon);
                            x.userInfoTue = JSON.parse(x.tue);
                            x.userInfoWed = JSON.parse(x.wed);
                            x.userInfoThu = JSON.parse(x.thu);
                            x.userInfoFri = JSON.parse(x.fri);
                            x.userInfoSat = JSON.parse(x.sat);
                            x.userInfoSun = JSON.parse(x.sun);
                        });
                        this.calendarData = response.data.data;
                        this.isLoading = false;
                    }
                    else {
                        this.calendarData = [];
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoading = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.calendarData = [];
                    this.isLoading = false;
                });
            },
            updateCalendar(id, staffId, dateOfWeek) {
                systemTrackingCalendar.updateCalendar({ id: id, dayOfWeek: this.dayOfWeek[parseInt(dateOfWeek)], staffId: staffId }).then(response => {
                    if (response.data && response.data.result === 0) {
                        this.$store.dispatch("common/getTechnicalShift");
                        this.$store.dispatch("common/getCalendarIndex");
                        this.getTechnicalShift();
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                });
            },
            changeWeek(offset) {
                this.isLoading = true;
                this.offsetWeek = this.offsetWeek + offset;
                this.getTechnicalShift();
            },
            dayInWeek(offsetDay) {
                moment().isoWeekday(1);
                const dateValue = moment().startOf('isoWeek').add(this.offsetWeek * 7 + offsetDay, 'days');
                return dateValue.format('DD-MM-YYYY');        
            },
        }
    }
</script>